import React from 'react';
import clsx from 'clsx';

import styles from './PriceCard.module.css';
import { PriceType } from '../../../contexts/AppConfigContext.types';
import { getPricelist } from '../../../utils/api';

export const PriceCard = ({ price }: { price: PriceType }) => {
  const onButtonClick = () => {
    getPricelist()
      .then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;

        if (/iPhone|iPad/i.test(navigator.userAgent)) {
          alink.download = 'pricelist.pdf'; // It doesn't open on the phone without downloading
        }
        alink.target = '_blank';
        alink.click();
      })
      .catch((err) => console.log(err));
  };
  return (
    <li className={styles.container}>
      <div className={styles.titleBox}>
        <h3 className={styles.title}>{price.title}</h3>
        {price.option === 'button' ? (
          <button
            type="button"
            onClick={onButtonClick}
            className={styles.priceButton}
          >
            <span className={clsx(styles.price, styles.shadowPulseFilter)}>
              {price.price}
            </span>
          </button>
        ) : (
          <p className={styles.price}>{price.price}</p>
        )}
      </div>
      <ul className={styles.list}>
        {price.list.map((item) => (
          <li className={styles.text} key={item}>{`${item}`}</li>
        ))}
      </ul>
    </li>
  );
};
